import React, { useEffect } from "react";
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css";

const CodeBlock = ({ children }) => {
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
        <pre
          className="bg-primary mt-1 text-white p-3 rounded"
          style={{ overflowX: "auto" }}
        >
          <code className="language-javascript">{children}</code>{" "}
        </pre>
  );
};

export default CodeBlock;
