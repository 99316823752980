import React, { useState, useEffect } from "react";
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css";

export default function Yarn() {
  const [copySuccess, setCopySuccess] = useState(false);
  const copyEmpty =
    "https://img.icons8.com/?size=100&id=pNYOTp5DinZ3&format=png&color=ffffff";
  const copyFull =
    "https://img.icons8.com/?size=100&id=AGLXTiVVflkt&format=png&color=ffffff";

  const command = "yarn add @headlockr/headlockr @tanstack/react-query@^5.56.2";

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const copyToClipboard = (text, place) => {
    navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");

    if (place === "commando") setCopySuccess(true);
  };

  return (
    <div>
      {" "}
      <div className="bg-secondary p-lg-2 rounded">
        <p className="text-light">
          Just run this command to start installing MFA for Strapi
        </p>
        <div className="d-flex align-items-center justify-content-between bg-primary p-1 mt-1 rounded">
          <code className="text-white">{command}</code>
          <div onClick={() => copyToClipboard(command, "commando")}>
            {copySuccess ? (
              <img
                src={copyFull}
                alt="Copy"
                style={{ width: "20px", cursor: "pointer" }}
              />
            ) : (
              <img
                src={copyEmpty}
                alt="Copy"
                style={{ width: "20px", cursor: "pointer" }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
