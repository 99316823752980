import React, { useState, useEffect } from "react";
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import { ClientOnly } from "remix-utils/client-only";

export default function RcYarn({ licenseKey }) {
  const [copySuccessCode, setCopySuccessCode] = useState(false);
  const copyEmpty =
    "https://img.icons8.com/?size=100&id=pNYOTp5DinZ3&format=png&color=ffffff";
  const copyFull =
    "https://img.icons8.com/?size=100&id=AGLXTiVVflkt&format=png&color=ffffff";

  const codeBlock = `@headlockr:registry=https://headlockr.nodejs.pub
//headlockr.nodejs.pub/:_authToken=${licenseKey}
always-auth=true`;

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const copyToClipboard = (text, place) => {
    navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");

    if (place === "codeBlock") setCopySuccessCode(true);
  };
  return (
    <div>
      {" "}
      <div className=" px-lg-2 mt-2 rounded">
        <p className="text-light">Add this to your .npmrc file</p>
        <div className="position-relative">
          <ClientOnly fallback={<p>Loading code block</p>}>
            {() => (
              <pre
                className="bg-primary mt-1 text-white p-3 rounded"
                style={{ overflowX: "auto" }}
              >
                <code className="language-javascript">{codeBlock}</code>{" "}
                {/* Use Prism.js language class */}
              </pre>
            )}
          </ClientOnly>
          <div
            className="position-absolute top-0 end-0 m-2"
            onClick={() => copyToClipboard(codeBlock, "codeBlock")}
          >
            {copySuccessCode ? (
              <img
                src={copyFull}
                alt="Copy"
                style={{ width: "20px", cursor: "pointer" }}
              />
            ) : (
              <img
                src={copyEmpty}
                alt="Copy"
                style={{ width: "20px", cursor: "pointer" }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
