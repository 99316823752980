import React, { Suspense, useState } from "react";
import CodeBlock from "./CodeBlock.client";
import { ClientOnly } from "remix-utils/client-only";

export default function Config({ licenseKey }) {
  const [copySuccess, setCopySuccess] = useState(false);
  const [copySuccessCode, setCopySuccessCode] = useState(false);
  const copyEmpty =
    "https://img.icons8.com/?size=100&id=pNYOTp5DinZ3&format=png&color=ffffff";
  const copyFull =
    "https://img.icons8.com/?size=100&id=AGLXTiVVflkt&format=png&color=ffffff";

  const codeBlock = `const HtmlWebpackPlugin = require("html-webpack-plugin");
const writeHeadlockrClientFiles = require("@headlockr/headlockr/client");

module.exports = (config) => {
  config.plugins = config.plugins.map((plugin) => {
    if (plugin instanceof HtmlWebpackPlugin) {
      return new HtmlWebpackPlugin({
        template: ".headlockr/client/index.html",
        inject: true,
      });
    }
    return plugin;
  });

  config.entry.main.push("./.headlockr/client/headlockr-admin-panel.tsx");

  config.plugins.push({
    apply: (compiler) => {
      compiler.hooks.beforeRun.tapPromise(
        "GenerateHeadlockrFiles",
        async () => {
          console.log("Generating Headlockr runtime files...");

          await writeHeadlockrClientFiles({
            runtimeDir: ".headlockr/client",
            logger: console,
          });

          console.log("Headlockr runtime files generated successfully.");
        }
      );
    },
  });

  // Important: return the modified config
  return config;
};
`;

  const copyToClipboard = (text, place) => {
    navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");

    if (place === "commando") setCopySuccess(true);
    if (place === "codeBlock") setCopySuccessCode(true);
  };
  return (
    <div>
      {" "}
      <div className=" px-lg-2 mt-1 rounded">
        <p className="text-light">
          In order for Headlockr to function properly in your admin panel,
          you'll need to make a slight modification to
          /src/admin/webpack.config.js file
        </p>
        <div className="position-relative">
          <ClientOnly
            fallback={
              <div>
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            }
          >
            {() => <CodeBlock language="javascript">{codeBlock}</CodeBlock>}
          </ClientOnly>

          <div
            className="position-absolute top-0 end-0 m-2"
            onClick={() => copyToClipboard(codeBlock, "codeBlock")}
          >
            {copySuccessCode ? (
              <img
                src={copyFull}
                alt="Copy"
                style={{ width: "20px", cursor: "pointer" }}
              />
            ) : (
              <img
                src={copyEmpty}
                alt="Copy"
                style={{ width: "20px", cursor: "pointer" }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
