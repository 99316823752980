import React from "react";
import styles from "./developers.module.scss";
export default function Intro() {
  return (
    <div className="col-12 col-md-6 offset-md-3">
      {" "}
      <h2 className="text-center">
        Seamless integration with <br className="d-none d-lg-block" /> your existing{" "}
        <span className="text-tertiary">Strapi</span> setup
      </h2>
      <p className={`text-center py-2 ${styles.paragraph}`}>
        No need for complex configurations. Our plugin is designed for quick and
        easy integration with Strapi, allowing you to enhance security without
        disrupting your workflow.
      </p>
    </div>
  );
}
