// Components
import Intro from "./Intro";
import Npm from "./Npm";
import Yarn from "./Yarn";
import RcNpm from "./RcNpm";
import RcYarn from "./RcYarn";
import Config from "./Config";
import AddLicense from "./License";

export default function Developers({ page, licenseKey }) {
  return (
    <div className="row mt-5">
      {page !== "checkout" && <Intro />}
      <div
        className={`col-12 col-md-8 offset-md-2`}
      >
        <div className="bg-secondary p-2 rounded-4">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                tabIndex={-1}
                className={`nav-link active`}
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home-tab-pane"
                type="button"
                role="tab"
                aria-controls="home-tab-pane"
                aria-selected="true"
              >
                Yarn
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                tabIndex={-1}
                className="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile-tab-pane"
                type="button"
                role="tab"
                aria-controls="profile-tab-pane"
                aria-selected="false"
              >
                Npm
              </button>
            </li>
          </ul>

          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home-tab-pane"
              role="tabpanel"
              aria-labelledby="home-tab"
              tabIndex={0}
            >
              <RcYarn licenseKey={licenseKey} />
              <Yarn />
            </div>
            <div
              className="tab-pane fade"
              id="profile-tab-pane"
              role="tabpanel"
              aria-labelledby="profile-tab"
              tabIndex={0}
            >
              <RcNpm licenseKey={licenseKey} />
              <Npm />
            </div>
          </div>
          <AddLicense licenseKey={licenseKey} />
          <Config licenseKey={licenseKey} />
          <div className="px-2 pb-2">
            <p className="mb-2">
              That’s it. Check out our{" "}
              <a
                className="text-tertiary"
                href="https://docs.headlockr.io"
                target="_blank"
                rel="noreferrer noopener"
              >
                documentation
              </a>{" "}
              to ensure you have the latest instructions.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
